<template>
  <div v-if="dialogVisible">
    <el-dialog title="履职表详情" :visible.sync="dialogVisible" width="50%">
      <div
        class="addbox"
        style="
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        "
      >
        <div class="infobox" ref="print" style="padding-bottom: 20px">
          <div
            style="
              color: #333;
              font-size: 35px;
              width: 100%;
              text-align: center;
            "
          >
            政协第十五届西宁市委员会委员<br />履职登记表
          </div>
          <div
            style="
              color: #333;
              font-size: 26px;
              width: 100%;
              text-align: center;
              margin-bottom: 30px;
              margin-top: 10px;
            "
          >
            {{ form.nian }}年12月
          </div>
          <div class="tablebox">
            <div class="line">
              <div class="namebox" style="width: 25%">姓名</div>
              <div class="value" style="width: 15%; border-right: none">
                {{ form.realname }}
              </div>
              <div class="namebox" style="width: 12%; border-right: none">
                性别
              </div>
              <div class="value" style="width: 15%; border-right: none">
                {{ form.gender }}
              </div>
              <div class="namebox" style="width: 15%; border-right: none">
                出生年月
              </div>
              <div class="value" style="width: 18%">
                {{ form.birthday }}
              </div>
            </div>
            <div class="line">
              <div class="namebox" style="width: 25%">党派</div>
              <div
                class="value"
                style="width: 15%; border-right: none"
                @click="showdp = true"
              >
                {{ form.dangpai }}
              </div>
              <div class="namebox" style="width: 12%; border-right: none">
                界别
              </div>
              <div
                class="value"
                style="width: 15%; border-right: none"
                @click="showjb = true"
              >
                {{ form.jiebie }}
              </div>
              <div class="namebox" style="width: 15%; border-right: none">
                联系电话
              </div>
              <div class="value" style="width: 18%">
                {{ form.mobile }}
              </div>
            </div>
            <div class="line autoheight" style="border-bottom: none">
              <div class="namebox">现工作单位及职务</div>
              <div class="value">
                {{ form.worker_zhiwu }}
              </div>
            </div>
            <div class="line autoheight" style="border-bottom: none">
              <div class="namebox">社会兼职情况</div>
              <div class="value">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ form.shjz_info }}
              </div>
            </div>
            <div
              class="line autoheight"
              style="min-height: 400px; border-bottom: none"
            >
              <div class="namebox">
                履职小结（“双岗双责、双作为”及“五个一”履职活动情况等）
              </div>
              <div
                class="value"
                style="
                  min-height: 400px;
                  display: flex;
                  flex-direction: column;
                  align-items: baseline;
                  justify-content: flex-start;
                "
              >
                <div
                  style="color: #333; font-size: 16px; margin: 10px 0"
                  v-for="(item, index) in form.form_summarize"
                >
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ index + 1 }}.{{
                    item.value
                  }}
                </div>
              </div>
            </div>
            <div class="line" style="height: 200px">
              <div class="namebox" style="width: 25%; height: 200px">
                参加省、市政协会议、培训活动情况
              </div>
              <div
                class="value"
                style="
                  width: 15%;
                  border-right: none;
                  height: 200px;
                  display: flex;
                  flex-direction: column;
                  padding: 0;
                "
              >
                <div
                  style="
                    height: 100px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  "
                >
                  全体会议<br />（次）
                </div>
                <div
                  style="
                    height: 100px;
                    border-top: 1px solid #333333;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0;
                  "
                >
                  {{ form.join_number1 }}
                </div>
              </div>
              <div
                class="value"
                style="
                  width: 15%;
                  border-right: none;
                  height: 200px;
                  display: flex;
                  flex-direction: column;
                  padding: 0;
                "
              >
                <div
                  style="
                    height: 100px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  "
                >
                  常委会议<br />（次）
                </div>
                <div
                  style="
                    height: 100px;
                    border-top: 1px solid #333333;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  "
                >
                  {{ form.join_number2 }}
                </div>
              </div>
              <div
                class="value"
                style="
                  width: 15%;
                  border-right: none;
                  height: 200px;
                  display: flex;
                  flex-direction: column;
                  padding: 0;
                "
              >
                <div
                  style="
                    height: 100px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  "
                >
                  双月协商、专题议政会议（次）
                </div>
                <div
                  style="
                    height: 100px;
                    border-top: 1px solid #333333;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  "
                >
                  {{ form.join_number3 }}
                </div>
              </div>
              <div
                class="value"
                style="
                  width: 15%;
                  border-right: none;
                  height: 200px;
                  display: flex;
                  flex-direction: column;
                  padding: 0;
                "
              >
                <div
                  style="
                    height: 100px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  "
                >
                  专委会议<br />（次）
                </div>
                <div
                  style="
                    height: 100px;
                    border-top: 1px solid #333333;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  "
                >
                  {{ form.join_number4 }}
                </div>
              </div>
              <div
                class="value"
                style="
                  width: 15%;
                  height: 200px;
                  display: flex;
                  flex-direction: column;
                  padding: 0;
                "
              >
                <div
                  style="
                    height: 100px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  "
                >
                  培训、学习<br />（次）
                </div>
                <div
                  style="
                    height: 100px;
                    border-top: 1px solid #333333;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  "
                >
                  {{ form.join_number5 }}
                </div>
              </div>
            </div>
            <div
              class="line autoheight"
              style="min-height: 220px; border-bottom: none"
            >
              <div class="namebox">
                会议发言（含大会、双月协商、专题性议政会议及专委会议等）
              </div>
              <div
                class="value"
                style="
                  min-height: 220px;
                  display: flex;
                  flex-direction: column;
                  align-items: baseline;
                  justify-content: flex-start;
                "
              >
                <div
                  style="color: #333; font-size: 16px; margin: 10px 0"
                  v-for="(item, index) in form.meeting_speak"
                >
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ index + 1 }}.{{
                    item.value
                  }}
                </div>
              </div>
            </div>
            <div
              class="line"
              style="
                min-height: 200px;
                height: auto;
                border: 1px solid #333;
                border-bottom: none;
                display: -webkit-inline-box;
              "
            >
              <div
                class="namebox"
                style="width: 25%; min-height: 200px; border: none"
              >
                参加省、市政协视察、调研等活动
              </div>
              <div
                class="value"
                style="
                  width: 25%;
                  border-left: 1px #333 solid;
                  min-height: 200px;
                  height: auto;
                  display: flex;
                  flex-direction: column;
                  padding: 0;
                  border-top: none;
                  justify-content: flex-start;
                "
              >
                <div
                  style="
                    height: 60px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  "
                >
                  调研
                </div>
                <div
                  style="
                    min-height: 140px;
                    height: auto;
                    border-top: 1px solid #333333;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                  "
                >
                  <div
                    style="color: #333; font-size: 16px; margin: 4px 0"
                    v-for="(item, index) in form.diaoyan"
                  >
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ index + 1 }}.{{
                      item.value
                    }}
                  </div>
                </div>
              </div>
              <div
                class="value"
                style="
                  width: 25%;
                  border-left: 1px #333 solid;
                  min-height: 200px;
                  height: auto;
                  display: flex;
                  flex-direction: column;
                  padding: 0;
                  border-top: none;
                  justify-content: flex-start;
                "
              >
                <div
                  style="
                    height: 60px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  "
                >
                  视察
                </div>
                <div
                  style="
                    min-height: 140px;
                    height: auto;
                    border-top: 1px solid #333333;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                  "
                >
                  <div
                    style="color: #333; font-size: 16px; margin: 4px 0"
                    v-for="(item, index) in form.shicha"
                  >
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ index + 1 }}.{{
                      item.value
                    }}
                  </div>
                </div>
              </div>
              <div
                class="value"
                style="
                  width: 25%;
                  min-height: 200px;
                  height: auto;
                  display: flex;
                  flex-direction: column;
                  padding: 0;
                  border: none;
                  justify-content: flex-start;
                "
              >
                <div
                  style="
                    height: 60px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  "
                >
                  其他
                </div>
                <div
                  style="
                    min-height: 140px;
                    height: auto;
                    border-top: 1px solid #333333;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                  "
                >
                  <div
                    style="color: #333; font-size: 16px; margin: 4px 0"
                    v-for="(item, index) in form.other"
                  >
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ index + 1 }}.{{
                      item.value
                    }}
                  </div>
                </div>
              </div>
            </div>
            <div
              class="line"
              style="
                min-height: 200px;
                height: auto;
                border: 1px solid #333;
                border-bottom: none;
                align-items: flex-start;
              "
            >
              <div
                class="namebox"
                style="width: 25%; min-height: 200px; border: none"
              >
                提案、意见建议及社情民意
              </div>
              <div
                class="value"
                style="
                  width: 50%;
                  border-right: 1px #333 solid;
                  border-left: 1px #333 solid;
                  min-height: 200px;
                  height: auto;
                  display: flex;
                  flex-direction: column;
                  padding: 0;
                  border-top: none;
                "
              >
                <div
                  style="
                    height: 60px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  "
                >
                  内容
                </div>
                <div
                  style="
                    min-height: 140px;
                    height: auto;
                    border-top: 1px solid #333333;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  "
                >
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{
                    form.tan_content
                  }}
                </div>
              </div>
              <div
                class="value"
                style="
                  width: 25%;
                  border-left: none;
                  min-height: 200px;
                  height: auto;
                  display: flex;
                  flex-direction: column;
                  padding: 0;
                  border-right: none;
                  border-top: none;
                "
              >
                <div
                  style="
                    height: 60px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  "
                >
                  立案/采用情况
                </div>
                <div
                  style="
                    min-height: 140px;
                    height: auto;
                    border-top: 1px solid #333333;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  "
                >
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{
                    form.tan_la_cq_info
                  }}
                </div>
              </div>
            </div>
            <div class="line autoheight" style="border-bottom: none">
              <div class="namebox">获得荣誉、奖惩情况</div>
              <div
                class="value"
                style="
                  min-height: 220px;
                  display: flex;
                  flex-direction: column;
                  align-items: baseline;
                  justify-content: flex-start;
                "
              >
                <div
                  style="
                    color: #333;
                    font-size: 16px;
                    margin: 10px 0;
                    display: flex;
                    align-items: center;
                  "
                  v-for="(item, index) in form.honor_punish_content"
                >
                  <div style="width: 90%">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ index + 1 }}.{{
                      item.value
                    }}
                  </div>
                  <el-button
                    type="primary"
                    size="mini"
                    @click="openurl(item.fileurl)"
                    >查看</el-button
                  >
                </div>
              </div>
            </div>
            <div class="line autoheight" style="border-bottom: none">
              <div class="namebox">
                加强与委员沟通交流、联系界别群众、发挥凝聚共识作用情况
              </div>
              <div class="value" style="min-height: 110px">
                {{ form.zyqk }}
              </div>
            </div>
            <div class="line autoheight" style="border-bottom: none">
              <div class="namebox">
                撰写关于政协理论学习文章、文史资料等情况
              </div>
              <div class="value" style="min-height: 110px">
                {{ form.wszl }}
              </div>
            </div>
            <!-- <div class="line autoheight" style="border-bottom: none;">
							<div class="namebox">
								所在单位意见
							</div>
							<div class="value">
								{{form.unit_opinion}}
							</div>
						</div> -->
            <div class="line autoheight" style="border-bottom: none">
              <div class="namebox">所在专门委员会意见</div>
              <div
                class="value"
                style="
                  justify-content: flex-start;
                  align-items: center;
                  text-align: left;
                "
              >
                <div style="width: 75%">
                  {{ form.spec_comm_opinion }}
                </div>
                <img
                  v-if="form.shenghe_user"
                  :src="form.shenghe_user.fullurl"
                  alt=""
                  style="width: 20%; height: auto; margin-left: 5%"
                />
              </div>
            </div>
            <div
              class="line"
              style="min-height: 100px; height: auto; border: 1px solid #333"
            >
              <div
                class="namebox"
                style="width: 25%; min-height: 100px; border: none"
              >
                评审意见
              </div>
              <div
                class="value"
                style="
                  width: 25%;
                  border-left: 1px #333 solid;
                  min-height: 100px;
                  height: auto;
                  display: flex;
                  flex-direction: column;
                  padding: 0;
                  border-top: none;
                "
              >
                <div
                  style="
                    height: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  "
                >
                  优秀
                </div>
                <div
                  style="min-height: 70px;height: auto;border-top: 1px solid #333333;width: 100%;display: flex;align-items: center;justify-content: center;po"
                >
                  <img
                    v-show="form.evaluate == '优秀'"
                    src="data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAYABgAAD/2wBDAAgGBgcGBQgHBwcJCQgKDBQNDAsLDBkSEw8UHRofHh0aHBwgJC4nICIsIxwcKDcpLDAxNDQ0Hyc5PTgyPC4zNDL/2wBDAQkJCQwLDBgNDRgyIRwhMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjL/wgARCAAsAC0DASIAAhEBAxEB/8QAGgABAAIDAQAAAAAAAAAAAAAAAAECAwQGBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAe/AKlgNbZgxeDfoBIAAAf/EAB8QAQACAQMFAAAAAAAAAAAAAAECAxAEEzAFERUhI//aAAgBAQABBQLhZETN90dPVu17QS6nYejCdzxn1ADm/8QAFBEBAAAAAAAAAAAAAAAAAAAAMP/aAAgBAwEBPwEf/8QAFBEBAAAAAAAAAAAAAAAAAAAAMP/aAAgBAgEBPwEf/8QAIxAAAQMCBQUAAAAAAAAAAAAAAQACERITAwQwMVEQFCBSYf/aAAgBAQAGPwLRlxAHJ8DiPBpHCu1CiJlVOluVbsPdQOsFRcPbTVa+qBtr/wD/xAAfEAEAAgEEAwEAAAAAAAAAAAABABEhEDAxUWGBkfD/2gAIAQEAAT8h2W5VylBBEsbNVCULrdT7VOKhw0utXb4gACg4NQYBHCMr7+PyoCABgDf/AP/aAAwDAQACAAMAAAAQ8808U8888//EABQRAQAAAAAAAAAAAAAAAAAAADD/2gAIAQMBAT8QH//EABQRAQAAAAAAAAAAAAAAAAAAADD/2gAIAQIBAT8QH//EACAQAQEAAQMEAwAAAAAAAAAAAAERABAhMDFBUWGR0fD/2gAIAQEAAT8Q4SOHQAe16YCAhRGianww3oLKng74NP77FyuNr7F+Bg4BABADUKLoFE8OO2gqUnrfnMM+oBADsc//2Q=="
                    alt=""
                  />
                </div>
              </div>
              <div
                class="value"
                style="
                  width: 25%;
                  border-left: 1px #333 solid;
                  min-height: 100px;
                  height: auto;
                  display: flex;
                  flex-direction: column;
                  padding: 0;
                  border-top: none;
                "
              >
                <div
                  style="
                    height: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  "
                >
                  称职
                </div>
                <div
                  style="
                    min-height: 70px;
                    height: auto;
                    border-top: 1px solid #333333;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  "
                >
                  <img
                    v-show="form.evaluate == '称职'"
                    src="data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAYABgAAD/2wBDAAgGBgcGBQgHBwcJCQgKDBQNDAsLDBkSEw8UHRofHh0aHBwgJC4nICIsIxwcKDcpLDAxNDQ0Hyc5PTgyPC4zNDL/2wBDAQkJCQwLDBgNDRgyIRwhMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjL/wgARCAAsAC0DASIAAhEBAxEB/8QAGgABAAIDAQAAAAAAAAAAAAAAAAECAwQGBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAe/AKlgNbZgxeDfoBIAAAf/EAB8QAQACAQMFAAAAAAAAAAAAAAECAxAEEzAFERUhI//aAAgBAQABBQLhZETN90dPVu17QS6nYejCdzxn1ADm/8QAFBEBAAAAAAAAAAAAAAAAAAAAMP/aAAgBAwEBPwEf/8QAFBEBAAAAAAAAAAAAAAAAAAAAMP/aAAgBAgEBPwEf/8QAIxAAAQMCBQUAAAAAAAAAAAAAAQACERITAwQwMVEQFCBSYf/aAAgBAQAGPwLRlxAHJ8DiPBpHCu1CiJlVOluVbsPdQOsFRcPbTVa+qBtr/wD/xAAfEAEAAgEEAwEAAAAAAAAAAAABABEhEDAxUWGBkfD/2gAIAQEAAT8h2W5VylBBEsbNVCULrdT7VOKhw0utXb4gACg4NQYBHCMr7+PyoCABgDf/AP/aAAwDAQACAAMAAAAQ8808U8888//EABQRAQAAAAAAAAAAAAAAAAAAADD/2gAIAQMBAT8QH//EABQRAQAAAAAAAAAAAAAAAAAAADD/2gAIAQIBAT8QH//EACAQAQEAAQMEAwAAAAAAAAAAAAERABAhMDFBUWGR0fD/2gAIAQEAAT8Q4SOHQAe16YCAhRGianww3oLKng74NP77FyuNr7F+Bg4BABADUKLoFE8OO2gqUnrfnMM+oBADsc//2Q=="
                    alt=""
                  />
                </div>
              </div>
              <div
                class="value"
                style="
                  width: 25%;
                  min-height: 100px;
                  height: auto;
                  display: flex;
                  flex-direction: column;
                  padding: 0;
                  border: none;
                "
              >
                <div
                  style="
                    height: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  "
                >
                  不称职
                </div>
                <div
                  style="
                    min-height: 70px;
                    height: auto;
                    border-top: 1px solid #333333;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  "
                >
                  <img
                    v-show="form.evaluate == '不称职'"
                    src="data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAYABgAAD/2wBDAAgGBgcGBQgHBwcJCQgKDBQNDAsLDBkSEw8UHRofHh0aHBwgJC4nICIsIxwcKDcpLDAxNDQ0Hyc5PTgyPC4zNDL/2wBDAQkJCQwLDBgNDRgyIRwhMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjL/wgARCAAsAC0DASIAAhEBAxEB/8QAGgABAAIDAQAAAAAAAAAAAAAAAAECAwQGBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAe/AKlgNbZgxeDfoBIAAAf/EAB8QAQACAQMFAAAAAAAAAAAAAAECAxAEEzAFERUhI//aAAgBAQABBQLhZETN90dPVu17QS6nYejCdzxn1ADm/8QAFBEBAAAAAAAAAAAAAAAAAAAAMP/aAAgBAwEBPwEf/8QAFBEBAAAAAAAAAAAAAAAAAAAAMP/aAAgBAgEBPwEf/8QAIxAAAQMCBQUAAAAAAAAAAAAAAQACERITAwQwMVEQFCBSYf/aAAgBAQAGPwLRlxAHJ8DiPBpHCu1CiJlVOluVbsPdQOsFRcPbTVa+qBtr/wD/xAAfEAEAAgEEAwEAAAAAAAAAAAABABEhEDAxUWGBkfD/2gAIAQEAAT8h2W5VylBBEsbNVCULrdT7VOKhw0utXb4gACg4NQYBHCMr7+PyoCABgDf/AP/aAAwDAQACAAMAAAAQ8808U8888//EABQRAQAAAAAAAAAAAAAAAAAAADD/2gAIAQMBAT8QH//EABQRAQAAAAAAAAAAAAAAAAAAADD/2gAIAQIBAT8QH//EACAQAQEAAQMEAwAAAAAAAAAAAAERABAhMDFBUWGR0fD/2gAIAQEAAT8Q4SOHQAe16YCAhRGianww3oLKng74NP77FyuNr7F+Bg4BABADUKLoFE8OO2gqUnrfnMM+oBADsc//2Q=="
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="btnbox">
          <el-button
            type="success"
            size="mini"
            class="font14"
            v-if="showsh"
            @click="pj"
            >评级</el-button
          >
          <el-button type="primary" size="mini" @click.stop="getword"
            >导出</el-button
          >
          <el-button type="primary" size="mini" @click.stop="print"
            >打印</el-button
          >
          <el-button
            type="info"
            size="mini"
            class="font14"
            @click="dialogVisible = false"
            >关闭</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { exportWord, getBase64Sync, getBase } from "@/utils/getword.js";
export default {
  props: {
    istz: {
      type: Boolean,
      default: false,
    },
    showsh: {
      type: Boolean,
      default: false,
    },
    updataQx: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      dialogVisible: false,
      form: "",
    };
  },
  created() {},
  methods: {
    async getword() {
      let gougou =
        "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAYABgAAD/2wBDAAgGBgcGBQgHBwcJCQgKDBQNDAsLDBkSEw8UHRofHh0aHBwgJC4nICIsIxwcKDcpLDAxNDQ0Hyc5PTgyPC4zNDL/2wBDAQkJCQwLDBgNDRgyIRwhMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjL/wgARCAAsAC0DASIAAhEBAxEB/8QAGgABAAIDAQAAAAAAAAAAAAAAAAECAwQGBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAe/AKlgNbZgxeDfoBIAAAf/EAB8QAQACAQMFAAAAAAAAAAAAAAECAxAEEzAFERUhI//aAAgBAQABBQLhZETN90dPVu17QS6nYejCdzxn1ADm/8QAFBEBAAAAAAAAAAAAAAAAAAAAMP/aAAgBAwEBPwEf/8QAFBEBAAAAAAAAAAAAAAAAAAAAMP/aAAgBAgEBPwEf/8QAIxAAAQMCBQUAAAAAAAAAAAAAAQACERITAwQwMVEQFCBSYf/aAAgBAQAGPwLRlxAHJ8DiPBpHCu1CiJlVOluVbsPdQOsFRcPbTVa+qBtr/wD/xAAfEAEAAgEEAwEAAAAAAAAAAAABABEhEDAxUWGBkfD/2gAIAQEAAT8h2W5VylBBEsbNVCULrdT7VOKhw0utXb4gACg4NQYBHCMr7+PyoCABgDf/AP/aAAwDAQACAAMAAAAQ8808U8888//EABQRAQAAAAAAAAAAAAAAAAAAADD/2gAIAQMBAT8QH//EABQRAQAAAAAAAAAAAAAAAAAAADD/2gAIAQIBAT8QH//EACAQAQEAAQMEAwAAAAAAAAAAAAERABAhMDFBUWGR0fD/2gAIAQEAAT8Q4SOHQAe16YCAhRGianww3oLKng74NP77FyuNr7F+Bg4BABADUKLoFE8OO2gqUnrfnMM+oBADsc//2Q==";
      let kong =
        "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAYABgAAD/2wBDAAgGBgcGBQgHBwcJCQgKDBQNDAsLDBkSEw8UHRofHh0aHBwgJC4nICIsIxwcKDcpLDAxNDQ0Hyc5PTgyPC4zNDL/2wBDAQkJCQwLDBgNDRgyIRwhMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjL/wgARCAARAA4DASIAAhEBAxEB/8QAFQABAQAAAAAAAAAAAAAAAAAAAAb/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAG/AB//xAAUEAEAAAAAAAAAAAAAAAAAAAAg/9oACAEBAAEFAl//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAUEAEAAAAAAAAAAAAAAAAAAAAg/9oACAEBAAY/Al//xAAUEAEAAAAAAAAAAAAAAAAAAAAg/9oACAEBAAE/IV//2gAMAwEAAgADAAAAEPPP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAFBABAAAAAAAAAAAAAAAAAAAAIP/aAAgBAQABPxBf/9k=";

      this.$post({
        url: "/api/user_performance_form/performance_from_details",
        params: {
          id: this.form.id,
        },
      }).then((res) => {
        this.form.form_summarize.forEach((item, index) => {
          item.iii = index + 1 + ".";
        });
        this.form.meeting_speak.forEach((item, index) => {
          item.iii = index + 1 + ".";
        });
        this.form.diaoyan.forEach((item, index) => {
          item.iii = index + 1 + ".";
        });
        this.form.shicha.forEach((item, index) => {
          item.iii = index + 1 + ".";
        });
        this.form.other.forEach((item, index) => {
          item.iii = index + 1 + ".";
        });
        this.form.honor_punish_content.forEach((item, index) => {
          item.iii = index + 1 + ".";
        });

        let data = {
          realname: this.form.realname, //姓名
          gender: this.form.gender, //性别
          nian: this.form.nian, //年
          birthday: this.form.birthday, //出生年月
          dangpai: this.form.dangpai, //党派
          jiebie: this.form.jiebie, //界别
          mobile: this.form.mobile, //联系电话
          worker_zhiwu: this.form.worker_zhiwu, //现工作单位及职务
          shjz_info: this.form.shjz_info, //社会兼职情况
          form_summarize: this.form.form_summarize, //履职小结（“双岗双责、双作为”及“五个一”履职活动情况等）
          join_number1: this.form.join_number1, //全体会议
          join_number2: this.form.join_number2, //常委会议
          join_number3: this.form.join_number3, //双月协商、专题议政会议
          join_number4: this.form.join_number4, //专委会议
          join_number5: this.form.join_number5, //培训、学习
          meeting_speak: this.form.meeting_speak, //会议发言
          diaoyan: this.form.diaoyan, //调研
          shicha: this.form.shicha, //视察
          other: this.form.other, //其他
          tan_content: this.form.tan_content, //内容
          tan_la_cq_info: this.form.tan_la_cq_info, //立案/采用情况
          honor_punish_content: this.form.honor_punish_content, //获得荣誉、奖惩情况
          zyqk: this.form.zyqk, //加强与委员沟通交流、联系界别群众、发挥凝聚共识作用情况
          wszl: this.form.wszl, //撰写关于政协理论学习文章、文史资料等情况
          spec_comm_opinion: this.form.spec_comm_opinion
            ? this.form.spec_comm_opinion
            : "", //所在专门委员会意见
          qianzi: res.data.fullurlbase64 ? res.data.fullurlbase64 : "", //签字图
          evaluate1: this.form.evaluate == "优秀" ? gougou : kong,
          evaluate2: this.form.evaluate == "称职" ? gougou : kong,
          evaluate3: this.form.evaluate == "不称职" ? gougou : kong,
        };
        const imgSize = {
          evaluate1: [30, 50],
          evaluate2: [30, 50],
          evaluate3: [30, 50],
          qianzi: [100, 50],
        };
        exportWord(
          "../static/lvzhi.docx",
          data,
          this.form.realname + "-" + this.form.nian + "履职登记表.docx",
          imgSize
        );
      });
    },
    //将base64格式的数据转为ArrayBuffer
    base64DataURLToArrayBuffer(dataURL) {
      const base64Regex = /^data:image\/(png|jpg|jpeg|svg|svg\+xml);base64,/;
      if (!base64Regex.test(dataURL)) {
        return false;
      }
      const stringBase64 = dataURL.replace(base64Regex, "");
      let binaryString;
      if (typeof window !== "undefined") {
        binaryString = window.atob(stringBase64);
      } else {
        binaryString = new Buffer(stringBase64, "base64").toString("binary");
      }
      const len = binaryString.length;
      const bytes = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
        const ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
      }
      return bytes.buffer;
    },
    print() {
      this.$printPage(this.$refs.print);
    },
    pj() {
      this.$emit("detailpj", this.form);
    },
    openurl(url) {
      window.open(url, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.valuename {
  width: 80%;
}

.tapmenu {
  font-size: 16px;
  margin-right: 30px;
  cursor: pointer;
  padding-bottom: 5px;
}

.active_tap {
  color: #409eff;
  border-bottom: 2px solid #409eff;
}

.item_line {
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  .tlename {
    width: 100px;
    font-size: 14px;
    text-align: right;
    color: #999;
  }

  .bticon {
    width: 10px;
    height: 20px;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    line-height: 34px;
    font-size: 26px;
    color: red;
  }
}

.infobox {
  width: 100%;
  padding-left: 50px;
  padding-right: 30px;
  height: 80vh;
  overflow-y: auto;
}

.addbox {
  max-height: 85vh;
  height: auto;
  width: 100%;
  overflow-y: auto;
  padding: 30px;
  background-color: #fff;
}

.add_cont {
  width: 100%;
  background-color: #fff;
  padding: 10px;
}

.btnbox {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 0;
  border-top: 1px solid #eee;
}

::v-deep .el-form-item__error {
  padding-top: 0;
}

::v-deep .el-form-item {
  margin-bottom: 10px;
}

::v-deep .el-form-item__label {
  color: #999;
  font-size: 14px;
}

::v-deep
  .el-form-item.is-required:not(.is-no-asterisk)
  > .el-form-item__label:before {
  content: "" !important;
}

::v-deep .el-input--small .el-input__icon {
  color: #333;
  font-weight: bold;
}

::v-deep .el-dialog {
  margin: 0 !important;
}

::v-deep .el-dialog__header {
  border-bottom: 1px solid #eee;
}

::v-deep .el-dialog__footer {
  border-top: 1px solid #eee;
}

::v-deep .el-dialog__body {
  padding: 10px;
  padding-top: 0px;
}

::v-deep .el-tabs--border-card {
  height: 100%;
}

::v-deep .el-tabs--border-card {
  box-shadow: 0 0 0 #fff;
}

::v-deep .el-tabs__header {
  background-color: #fff;
}

::v-deep .myinputicon {
  cursor: pointer;
}

::v-deep .myinputicon:hover {
  color: red !important;
}

::v-deep .el-dialog__wrapper {
  z-index: 2000 !important;
}

.mytablebox {
  padding: 10px;
}

.line {
  display: flex;
  align-items: center;
  height: 50px;
  width: 100%;
}

.namebox {
  height: 100%;
  width: 12%;
  border: 1px solid #333;
  padding: 7px 2px;
  text-align: center;
  border-right: none;
  border-bottom: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;

  text {
    width: 100%;
  }
}

.namebox:nth-last-child(1) {
  border-right: 1px solid #333;
}

.value {
  height: 100%;
  width: 35%;
  padding: 7px;
  text-align: left;
  border: 1px solid #333;
  word-wrap: break-word;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: none;
  font-size: 16px;

  text {
    width: 100%;
  }
}

.value:nth-child(2) {
  border-right: none;
}

.line:nth-last-child(1) {
  border-bottom: 1px solid #333;
}

.autoheight {
  min-height: 60px;
  height: auto;
  border: 1px solid #333;

  .namebox {
    width: 25%;
    border: none;
  }

  .value {
    min-height: 60px;
    width: 75%;
    border: none;
    border-left: 1px solid #333;
  }
}
</style>
